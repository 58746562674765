
export default function (siteKey) {
 return `<h2 class="h1 font-weight-bold mt-4 text-dark pb-3">
Forgot Password
</h2>
<form onsubmit="return false">
<div class="pb-3">
<p class="text-secondary">
  To reset your password, enter your <span class="text-primary">Optimiser's</span> registered
  User ID.
</p>
<p class="text-danger link-expired d-none">The link to reset your password has expired. Please try again.</p>
</div>
<div class="form-group">
  <label for="exampleInputEmail1">Username</label>
  <input type="text" placeholder="useremail@company.com" required="" class="form-control txtUserName mb-2">
  <div class="text-secondary">This site is protected by reCAPTCHA and the Google
    <a href="https://policies.google.com/privacy">Privacy Policy</a> and
    <a href="https://policies.google.com/terms">Terms of Service</a> apply. 
  </div>
  <p class="text-danger notValid d-none">Please enter valid username.</p>
  <p class="text-danger login-error pt-2"></p>
  <div class="captchaDiv">
  </div>
  <p class="captchaError text-danger"> </p> 
</div>

<div class="form-group">
  <button type="button" class="btn btn-primary btn-lg btn-block btn-login resetPassword g-recaptcha" data-sitekey=${siteKey}>
    Reset Password
    <!---<span class="spinner-border spinner-border-sm d-none"></span> ---->
  </button>
</div>
<hr>

<div class="col-md-12 text-center mt-4"><span class="font-size-14 mr-2">Need help with Login?
                    </span><a href="https://www.optimiser.com/contact-us" target="_blank"
                        class="btn btn-outline-primary ml-2 w-40 px-4">Contact Us
                    </a></div>
</form>

`}