import _constantClient from "./constants";
let isExtnApp = false;

export const HasSession = () => {
    if (_constantClient.APP_NAME == 'emailintegration') {
        isExtnApp = true;
        return localStorage.getItem('msp_d');
    } else {
        return document.cookie.indexOf('msp_d=') !== -1;
    }
}

export const PageTarget = () => {
    let params = new URLSearchParams(window.location.search);
    let page = params.get('page');

    if (!page && !HasSession()) {
        return isExtnApp ? "ExtnAppLogin" : "Login";
    }
    if (!page && HasSession()) {
        return isExtnApp ? "ExtnAppRoot" : "Root";
    }
    return page;
}